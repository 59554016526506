import { render, staticRenderFns } from "./NewReferant.vue?vue&type=template&id=646968da&"
import script from "./NewReferant.vue?vue&type=script&lang=js&"
export * from "./NewReferant.vue?vue&type=script&lang=js&"
import style0 from "./NewReferant.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports