<template>
    <div class="type-selector">
        <div class="vx-row">
            <div
                class="vx-col sm:tw-w-1/2 tw-w-full mb-5"
                v-for="(type, typeIndex) in types"
                :key="typeIndex"
            >
                <div
                    :class="[
                        'toggle',
                        isTypeSelected(type) ? 'toggle-selected' : '',
                        disabled ? 'toggle-disabled' : ''
                    ]"
                    @click="onTypeBtnClick(type)"
                    :data-cy="`type-selector-${type.id}`"
                >
                    <!-- <div class="toggle-circle"></div> -->
                    <div class="toggle-text">
                        <span class="toggle-type">{{ type.name }}</span> {{ type.label }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TypeSelector',
    props: [
        'value',
        'types',
        'disabled'
    ],
    methods: {
        isTypeSelected (type) {
            return this.value ? this.value == type.id : false
        },
        onTypeBtnClick (type) {
            if (this.disabled) {
                return
            }
            this.$emit('change', type.id)
        }
    }
}
</script>

<style lang="scss">
.type-selector {
    margin: 21px 0px 0px 0px;

    .toggle {
        display: flex;
        padding: 12px 12px 12px 12px;
        border: 1px solid rgba(var(--vs-primary), 1);
        border-radius: 4px;
        color: #2C2C2C;
        cursor: pointer;

        .toggle-circle {
            width: 18px;
            height: 18px;
            margin: 0px 10px 0px 0px;
            border-radius: 20px;
            border: 1px solid #979797;
        }
        .toggle-text {

        }
        .toggle-type {
            font-weight: bold;
        }
    }
    .toggle-selected {
        background: rgba(var(--vs-primary), 1);
        border: 1px solid rgba(var(--vs-primary), 1);
        box-shadow: -1px 1px 8px rgba(126, 114, 242, 0.2);
        color: white;

        .toggle-circle {
            border: 2px solid white;
        }
    }
    .toggle-disabled {
        cursor: default;
    }
}
</style>
