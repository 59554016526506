<template>
    <div id="new-referant" class="data-list-container">

        <vs-button
            class="back-btn"
            icon="keyboard_arrow_left"
            type="border"
            @click="onBackBtnClick"
        >Retour</vs-button>

        <div class="form-header">Créer un nouveau tuteur / proche</div>
        <div class="form-legend">
            Renseigner les informations du tuteur / proche du résident,
            un email d’activation de compte lui sera envoyé
            une fois enregistré.
        </div>

        <HCard class="form">

            <div class="form-title">Identité</div>
            <TypeSelector
                :types="referantTypes"
                :value="referant.type"
                @change="onTypeChange"
            />
            <div
                v-if="fieldErrors.type"
                class="form-field-error"
            >{{ fieldErrors.type }}</div>

            <div class="civility">
                <vs-radio
                    v-model="referant.civility"
                    class="female"
                    vs-name="civility"
                    vs-value="Mme"
                    data-cy="civility-female-radio"
                >Madame</vs-radio>
                <vs-radio
                    v-model="referant.civility"
                    class="male"
                    vs-name="civility"
                    vs-value="M"
                    data-cy="civility-male-radio"
                >Monsieur</vs-radio>
            </div>
            <div
                v-if="fieldErrors.civility"
                class="form-field-error"
            >{{ fieldErrors.civility }}</div>

            <div class="vx-row">
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">NOM</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            v-model="referant.lastName"
                            autofocus
                            class="form-input tw-w-full last-name"
                            placeholder="Nom du tuteur / proche"
                            type="text"
                            v-on:keyup="onLastNameChange"
                            data-cy="lastName-field"
                        />
                        <div
                            v-if="fieldErrors.lastName"
                            class="form-field-error"
                        >{{ fieldErrors.lastName }}</div>
                    </div>
                </div>
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Prénom</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            v-model="referant.firstName"
                            class="form-input tw-w-full"
                            placeholder="Prénom du tuteur / proche"
                            type="text"
                            v-on:keyup="onFirstNameChange"
                            data-cy="firstName-field"
                        />
                        <div
                            v-if="fieldErrors.firstName"
                            class="form-field-error"
                        >{{ fieldErrors.firstName }}</div>
                    </div>
                </div>
            </div>

            <div class="form-separator"></div>

            <div class="form-title">Contact</div>
            <div class="form-legend"></div>

            <div class="vx-row">
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Email</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            v-model="referant.email"
                            class="form-input tw-w-full"
                            placeholder="nomdumail@chro-ehpad.com"
                            type="text"
                            data-cy="email-field"
                        />
                        <div
                            v-if="fieldErrors.email"
                            class="form-field-error"
                        >{{ fieldErrors.email }}</div>
                    </div>
                </div>
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Numéro de téléphone</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            v-model="referant.phoneNumber"
                            class="form-input tw-w-full"
                            placeholder="+33"
                            type="text"
                            @focus="onPhoneFocus"
                            v-on:keyup="onPhoneChange"
                            data-cy="phone-field"
                        />
                        <div
                            v-if="fieldErrors.phone"
                            class="form-field-error"
                        >{{ fieldErrors.phone }}</div>
                    </div>
                </div>
            </div>

            <div class="form-actions">
                <vs-button
                    class="cancel-btn"
                    type="border"
                    @click="onBackBtnClick"
                >Annuler</vs-button>
                <vs-button
                    :disabled="!isFieldsValidBtn()"
                    class="save-btn"
                    @click="onSaveBtnClick"
                    data-cy="save-btn"
                >Enregistrer</vs-button>
            </div>

        </HCard>
    </div>
</template>

<script>
import Regexes from '@/constants/regexes'
import Forms from '@/constants/forms'
import Routes from '@/constants/routes'

import {
    capitalize
} from '@/modules/strings'

import {
    HCard
} from '@happytal/bo-ui-library'
import TypeSelector from '@/components/selectors/TypeSelector'

export default {
  name: 'NewReferant',
  components: {
      TypeSelector,
      HCard
  },
  data() {
    return {
      locked: false,
      referant: {
        email: '',
        firstName: '',
        lastName: '',
        type: null,
        civility: null,
        phoneNumber: '',
        institutionIds: []
      },
      referantTypes: [
        {
          id: 'Tutor', name: `Tuteur`, label: `(Personne chargée légalement
                de veiller sur le résident)`
        },
        {
          id: 'Family', name: `Proche`, label: `(Personne de la famille chargée
                de veiller sur le résident)`
        }
      ],
      fieldErrors: {}
    }
  },
  methods: {
    clear() {
      this.referant = {
        email: '',
        firstName: '',
        lastName: '',
        type: null,
        civility: null,
        phoneNumber: '',
        institutionIds: []
      }
    },
    onPhoneFocus(e) {
      if (!this.referant.phone) {
        this.referant.phone = '+33'
      }
    },
    onFirstNameChange(e) {
      const charCode = e.which || e.keyCode || e.key
      if (charCode < 48 || charCode > 90) {
        return
      }
      const firstName = this.referant.firstName
      if (firstName) {
        this.referant.firstName = capitalize(firstName, true)
      }
    },
    onLastNameChange(e) {
      const charCode = e.which || e.keyCode || e.key
      if (charCode < 48 || charCode > 90) {
        return
      }
      const lastName = this.referant.lastName
      if (lastName) {
        this.referant.lastName = lastName.toUpperCase()
      }
    },
    onPhoneChange(e) {
      const charCode = e.which || e.keyCode || e.key
      if (charCode < 48 || charCode > 90) {
        return
      }
      const phone = this.referant.contactPhoneNumber
      if (phone) {
        this.referant.phone = phone.slice(0, 12)
      }
    },
    checkFields() {
      return true
    },
    onTypeChange(id) {
      this.referant.type = id
    },
    isFieldsValidBtn() {
      if (!this.referant.type) {
        return false
      }
      if (!this.referant.civility) {
        return false
      }
      if (!this.referant.lastName || !Regexes.LastName.test(this.referant.lastName)) {
        return false
      }
      if (! this.referant.firstName || !Regexes.FirstName.test( this.referant.firstName)) {
        return false
      }
      if ( this.referant.phoneNumber && !Regexes.Phone.test( this.referant.phoneNumber)) {
        return false
      }
      if (!this.referant.email || !Regexes.Email.test(this.referant.email)) {
        return false
      }
      return true
    },
    isFieldValid() {
      this.fieldErrors = {}
      const type = this.referant.type
      if (!type) {
        this.fieldErrors.type = `Vous devez sélectionner un rôle.`
        return false
      }
      const civility = this.referant.civility
      if (!civility) {
        this.fieldErrors.civility = `Vous devez sélectionner une civilité.`
        return false
      }
      const lastName = this.referant.lastName
      if (!lastName || !Regexes.LastName.test(lastName)) {
        this.fieldErrors.lastName = `Le nom est invalide.`
        return false
      }
      const firstName = this.referant.firstName
      if (!firstName || !Regexes.FirstName.test(firstName)) {
        this.fieldErrors.firstName = `Le prénom est invalide.`
        return false
      }
      const phone = this.referant.phoneNumber
      if (phone && !Regexes.Phone.test(phone)) {
        this.fieldErrors.phone = `Le numéro de téléphone est invalide.`
        return false
      }
      const email = this.referant.email
      if (!email || !Regexes.Email.test(email)) {
        this.fieldErrors.email = `L'email est invalide.`
        return false
      }
      return true
    },
    goBack() {
      const cypressUser = _.get(this.$store, 'state.dataUser.user.auth.cypressUser', false)
      if (!cypressUser) {
        this.$router.go(-1)
      }
    },
    onBackBtnClick(e) {
      this.goBack()
    },
    onSaveBtnClick(e) {
      if (this.locked || !this.isFieldValid()) {
        return
      }
      this.locked = true
      this.referant.institutionIds = [parseInt(this.getInstitutionId(), 10)]
      this.$store.dispatch('dataTutors/createReferant', {
        referant: this.referant
      })
      .then((newReferant) => {
            this.saved = true
            this.locked = false
            // Save new referant form fields
            this.$store.commit('dataForms/SET_FORM', {
              formId: Forms.NewReferant,
              data: newReferant
            })
            const typeLabel = newReferant.type == 'Tutor' ? 'tuteur' : 'proche'
            this.showInfoToast(`Le ${typeLabel} a bien été créé.`)
            this.goBack()
          },
          (error) => {
            this.showErrorToast(`Une erreur s'est produite (${error}).`)
            this.locked = false
        })
    },
    /*onStepClick (step) {
        this.stepSelected = step.id
    },*/
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    showInfoToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: 5000,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    },
    showErrorToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: null,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    }
  },
  mounted() {
    this.clear()
    this.locked = false
    this.saved = false
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved) {
      next()
      return
    }
    this.$vs.dialog({
      type: 'confirm',
      color: 'primary',
      title: `Annuler la création`,
      text: `Êtes-vous sûr de vouloir quitter cette page sans enregistrer ? Toutes les données renseignées seront perdues.`,
      acceptText: `Oui`,
      cancelText: `Non`,
      accept: () => {
        next()
      },
      cancel: () => {
        next(false)
      }
    })
  }
}
</script>

<style lang="scss">
#new-referant {
  .back-btn {
    margin: 0px 0px 20px 0px;
  }

  .civility {
    display: flex;
    margin: 33px 0px 0px 0px;

    .male {
      margin: 0px 0px 0px 52px;
    }

    .female {

    }
  }

  .cancel-btn {
    margin: 0px 25px 0px 0px;
  }

  .save-btn {

  }
}
</style>
